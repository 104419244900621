<template>
  <div class="faq">
  <div class="faq2">
    <p>FAQ</p>
    <h1>よくあるご質問</h1>

    <div class="faq_class">

    <details>
      <summary aria-controls="accordion01" aria-selected="false" aria-expanded="false">
      <dl class="faq1">
        <dt>Q1</dt><dd>定休日はありますか？</dd>
      </dl>
      </summary>
      <div id="accordion01" class="answer" aria-hidden="true">
        <p>定休日は日曜・祝日です。12月は日曜・祝日も営業しています。</p>
      </div>
    </details>

    <details>
      <summary aria-controls="accordion02" aria-selected="true" aria-expanded="true">
      <dl class="faq1">
        <dt>Q2</dt><dd>作業は予約制ですか？</dd>
      </dl>
      </summary>
        <div id="accordion02" class="answer" aria-hidden="false">
          <p>ご来店していただいた順番で受付を行っているため、予約は承っておりません。</p>
      </div>
    </details>

    <details>
      <summary aria-controls="accordion03" aria-selected="false" aria-expanded="false">
      <dl class="faq1">
        <dt>Q3</dt><dd>タイヤの買取はできますか？</dd>
      </dl>
      </summary>
        <div id="accordion03" class="answer" aria-hidden="true">
          <p>タイヤの買取は行っていません。<br>廃タイヤの処分は有料で可能です。</p>
      </div>
    </details>
    <details>
      <summary aria-controls="accordion04" aria-selected="false" aria-expanded="false">
      <dl class="faq1">
        <dt>Q4</dt><dd>いつ空いてますか？</dd>
      </dl>
      </summary>
        <div id="accordion01" class="answer" aria-hidden="true">
          <p>8:00から18:00まで営業しています。</p>
      </div>
    </details>

    <details>
      <summary aria-controls="accordion05" aria-selected="true" aria-expanded="true">
      <dl class="faq1">
        <dt>Q5</dt><dd>トラックの作業は行っていますか？</dd>
      </dl>
      </summary>
        <div id="accordion02" class="answer" aria-hidden="false">
          <p>トラックやダンプの作業可能です。詳しくはお問い合わせください。</p>
      </div>
    </details>

    <details>
      <summary aria-controls="accordion06" aria-selected="false" aria-expanded="false">
      <dl class="faq1">
        <dt>Q6</dt><dd>タイヤのレンタルはやっていますか？</dd>
      </dl>
      </summary>
        <div id="accordion03" class="answer" aria-hidden="true">
          <p>レンタルサービスはしておりません。</p>
      </div>
    </details>

    <details>
      <summary aria-controls="accordion01" aria-selected="false" aria-expanded="false">
      <dl class="faq1">
        <dt>Q7</dt><dd>タイヤチェーンの在庫はありますか？</dd>
      </dl>
      </summary>
      <div id="accordion01" class="answer" aria-hidden="true">
        <p>在庫はしていませんが、店頭でご注文は承っております。</p>
      </div>
    </details>



    <details>
      <summary aria-controls="accordion03" aria-selected="false" aria-expanded="false">
      <dl class="faq1">
        <dt>Q8</dt><dd>パンクしてしまいレッカーで入庫できますか？</dd>
      </dl>
      </summary>
        <div id="accordion03" class="answer" aria-hidden="true">
          <p>はい、駐車場にレッカー車が入るスペースがございます。
          タイヤの在庫状況によりご対応できかねる場合がございますので、
          タイヤ側面にあるタイヤサイズを確認の上、お電話にて当店にご連絡お願いいたします。<br>
          (タイヤサイズ例: 195/65R15 91H)<br><br>
          レッカーはJAFや任意保険のロードサービスをご利用ください。</p>
      </div>
    </details>
    <details>
      <summary aria-controls="accordion04" aria-selected="false" aria-expanded="false">
      <dl class="faq1">
        <dt>Q9</dt><dd>タイヤの販売はブリヂストンだけですか？</dd>
      </dl>
      </summary>
        <div id="accordion01" class="answer" aria-hidden="true">
          <p>ブリヂストンのみの販売となります。</p>
      </div>
    </details>

    <details>
      <summary aria-controls="accordion05" aria-selected="true" aria-expanded="true">
      <dl class="faq1">
        <dt>Q10</dt><dd>作業を見学しても良いですか？</dd>
      </dl>
      </summary>
        <div id="accordion02" class="answer" aria-hidden="false">
          <p>作業中のピット内は大変危険ですので、立ち入りをご遠慮いただいております。</p>
      </div>
    </details>

    <details>
      <summary aria-controls="accordion06" aria-selected="false" aria-expanded="false">
      <dl class="faq1">
        <dt>Q11</dt><dd>待つスペースはありますか？</dd>
      </dl>
      </summary>
        <div id="accordion03" class="answer" aria-hidden="true">
          <p>テーブル席がございます。</p>
      </div>
    </details>

    </div>
    </div>

    <div class="access_faq">
      <p class="about_p">ACCESS</p>
      <h1 class="access_faq_h1">勝間田タイヤ</h1>
      <p>静岡県御殿場市茱萸沢241-1</p>
      <a href="https://goo.gl/maps/68HfK1CfSBGu4P1P9">Google Maps</a>
    </div>

    <div>
    <Footer/>
    </div>


  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'Home',
  components: {
    Footer
  }
}
</script>

<style scoped>
.img_faq1{
  width: 40%;
  height: 40%;
  margin-right: 0rem;
  padding-top: 2rem;
  padding-bottom: 3rem;
}
.inside_class{
  width: 100%;
  padding-top: 6rem;
  margin-bottom: -5rem
}

.faq{
  padding-left: 0rem;
  padding-right: 0rem;
}
.faq2{
  padding-left: 2rem;
  padding-right: 2rem;
}


.faq h1{
  margin-top: -0.5rem;
  text-align:left;
  font-size: 28px;
}
.faq p{
  padding-top: 6rem;
  text-align:left;
  font-size: 0.9rem;
}


.faq1{
    font-size: 1rem;
    line-height: 1.8;
    letter-spacing: 0.03em;
}
.faq1 dd{
}
.faq2{
    font-size: 0.9rem;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: bold;
    padding-bottom: 4rem;
    line-height: 1.8;
    letter-spacing: 0.03em;
}
.faq2 dd{
    padding-bottom: 0.7rem;
}
.faq3{
    font-size: 0.9rem;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: bold;
    padding-bottom: 3rem;
    line-height: 1.8;
    letter-spacing: 0.03em;
}
.faq3 dd{
    padding-bottom: 0.7rem;
}
.faq4{
    font-size: 0.9rem;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: bold;
    padding-bottom: 4rem;
    line-height: 1.8;
    letter-spacing: 0.03em;
}
.faq4 dd{
    padding-bottom: 0.7rem;
}
.faq5{
    font-size: 0.9rem;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: bold;
    padding-bottom: 4rem;
    line-height: 1.8;
    letter-spacing: 0.03em;
}
.faq5 dd{
    padding-bottom: 0.7rem;
}
.faq6{
    font-size: 0.9rem;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: bold;
    padding-bottom: 4rem;
    line-height: 1.8;
    letter-spacing: 0.03em;
}
.faq6 dd{
    padding-bottom: 0.7rem;
}
.faq7{
    font-size: 0.9rem;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: bold;
    padding-bottom: 4rem;
    line-height: 1.8;
    letter-spacing: 0.03em;
}
.faq7 dd{
    padding-bottom: 0.7rem;
}
.faq8{
    font-size: 0.9rem;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: bold;
    padding-bottom: 4rem;
    line-height: 1.8;
    letter-spacing: 0.03em;
}
.faq8 dd{
    padding-bottom: 0.7rem;
}
.faq9{
    font-size: 0.9rem;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: bold;
    padding-bottom: 4rem;
    line-height: 1.8;
    letter-spacing: 0.03em;
}
.faq9 dd{
    padding-bottom: 0.7rem;
}
.faq10{
    font-size: 0.9rem;
    font-family: TTNormsPro-Bold,sans-serif;
    font-weight: bold;
    padding-bottom: 4rem;
    line-height: 1.8;
    letter-spacing: 0.03em;
}
.faq10 dd{
    padding-bottom: 0.7rem;
}
.faq_class dt{
  float: left;
  font-size: 1rem;
}

.faq_class dt::after{
  content: "."
}
.faq_class dd{
  text-align:left;
  font-size: 0.95rem;
}
.faq_class{
  padding-bottom: 7rem;
}

details {
  margin: 0 auto 25px;
  width: 100%;
}

summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 30px;
  font-size: 1rem;
  background-color: #d6d6d6;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 500;
  text-align:left;
}

summary::-webkit-details-marker {
  display: none;
}

summary:hover,
details[open] summary {
  background-color: #bbb;
}

summary::after {
  content: '+';
  margin-left: 30px;
  color: #5b8f8f;
  font-size: 21px;
  transition: transform .5s;
}

details[open] summary::after {
  transform: rotate(45deg);
}

.answer {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: -4rem;
  padding-bottom: 2rem;
  line-height: 1.6;
  font-weight: 500;
}


details[open] .answer {
  animation: fadein .5s ease;
}

@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.faq a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 160px;
  height: 50px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  background-color: #9099a2;
  border-radius: 40px;
  margin-top: 30px;
}
.faq a::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #d5d5d5;
  border-right: 2px solid #d5d5d5;
  transform: rotate(45deg);
}
.access_faq{
  margin-top: -10rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 5rem;
}
.about_p{
  padding-top: 7.3rem;
  text-align:left;
  font-size: 0.8rem;
  font-weight: 500;
}

.access_faq_h1{
  margin-bottom: -5rem;
}
</style>
